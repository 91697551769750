import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Loader } from './components/loader';
import './index.css';
import App from './App';
import store from './store';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://1097da3c8f8f4f7798b74d313c835f11@o1281312.ingest.sentry.io/6487185",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <Suspense fallback={
    <div className='h-100 w-100 d-flex'>
      <Loader />
    </div>
  }>
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </Provider>
    </React.StrictMode >
  </Suspense>,
  document.getElementById('root')
);

