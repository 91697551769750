const initState = {
    cities: [],
    countries: [],
    search: ''
};

export const constReducer = (state = initState, action) => {
    switch(action.type) {
        case 'SET_CITY': {
            return ({
                ...state,
                cities: action.payload
            });
        }
        case 'SET_COUNTRY': {
            return ({
                ...state,
                countries: action.payload
            });
        }
        case 'SET_SEARCH': {
            return ({
                ...state,
                search: action.payload
            });
        }
        default: 
            return state;
    }
}