import React, { useState } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { logout } from '../../store/action/user';

import './styles.less';
import { Button } from 'antd';

export function MainHeader() {

    const [mobileNavVisible, setMobileNavVisible] = useState(false);
    const breakpoints = useBreakpoint();

    let dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    let [, , removeCookie] = useCookies(['jwt']);

    const { _id, role } = useSelector(st => st.user);

    async function _logout() {
        try {
            removeCookie('jwt')
            dispatch(logout())
            navigate('/');
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <React.Fragment>
            {/* {
                location.pathname === '/' &&
                <h6 className='text-center bg-light mb-0 fst-italic fw-bold' style={{ lineHeight: '36px' }}>
                    Welcome to our Beta website!
                </h6>
            } */}
            <div className="header position-relative">
                <div className='container'>
                    <nav className='w-100 d-flex flex-md-row justify-content-between'>
                        <div className='logo d-flex align-items-center justify-content-center'>
                            <Link to='/' className='text-decoration-none'>
                                <img src='/img/home/logo-without-bg.png' alt='Hoffero' />
                            </Link>
                        </div>

                        {
                            _id &&
                            <ul className="nav d-none d-xl-flex">
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to='/dashboard'
                                    >
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to='/claim'
                                    >
                                        Claim Hotel
                                    </NavLink>
                                </li>
                                {
                                    role === 'ADMIN' &&
                                    <React.Fragment>
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to='/claim_requests'
                                            >
                                                Claim Requests
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to='/destinations'
                                            >
                                                Destinations
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to='/add_requests'
                                            >
                                                Add Requests
                                            </NavLink>
                                        </li>

                                    </React.Fragment>
                                }
                                <li
                                    className="nav-item pointer"
                                    onClick={_logout}
                                >
                                    <span className='nav-link'>
                                        Logout
                                    </span>
                                </li>
                            </ul>
                        }
                        {
                            _id ?
                                null
                                :
                                location.pathname === '/claim'
                                    ?
                                    <Button type='primary' size='large' onClick={() => navigate('/login')} style={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        border: 'none',
                                    }}
                                        className='d-flex flex-row justify-content-between align-items-center'
                                    >
                                        <span
                                            className='m-0'
                                            style={{
                                                fontWeight: '500',
                                            }}
                                        >Login</span>
                                        <ArrowRightOutlined />
                                    </Button>
                                    :
                                    (location.pathname === '/' || location.pathname === '/hotels')
                                        ?
                                        <div>
                                            <div
                                                className='flex-column justify-content-between align-items-center flex-1 d-flex d-md-none'
                                            >
                                                <Button type='primary' size='large' onClick={() => navigate('/claim')} style={{
                                                    backgroundColor: '#000',
                                                    color: '#fff',
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                }}
                                                    className='d-flex flex-row justify-content-between align-items-center'
                                                >
                                                    <span
                                                        className='m-0'
                                                        style={{
                                                            fontWeight: '500',
                                                        }}
                                                    >Claim Your Hotel</span>
                                                    <ArrowRightOutlined />
                                                </Button>

                                            </div>
                                            <div
                                                className='flex-row justify-content-between align-items-center flex-1 d-none d-md-flex'
                                            >
                                                <NavLink
                                                    className="nav-link"
                                                    to='/#home-hotels'
                                                    style={{ color: '#000', fontSize: 16 }}
                                                >
                                                    Featured Hotels
                                                </NavLink>
                                                <NavLink
                                                    className="nav-link"
                                                    to='/#home-destinations'
                                                    style={{ color: '#000', fontSize: 16 }}
                                                >
                                                    Featured Destinations
                                                </NavLink>
                                                <NavLink
                                                    className="nav-link"
                                                    to='/#how-it-works'
                                                    style={{ color: '#000', fontSize: 16 }}
                                                >
                                                    How it works
                                                </NavLink>
                                                <NavLink
                                                    className="nav-link"
                                                    to='/#why-choose-us'
                                                    style={{ color: '#000', fontSize: 16 }}
                                                >
                                                    Why Choose Us
                                                </NavLink>
                                                {/* <NavLink
                                                    className="nav-link"
                                                    to='/#faqs'
                                                    style={{ color: '#000', fontSize: 15 }}
                                                >
                                                    FAQs
                                                </NavLink> */}
                                                <Button type='primary' size='large' onClick={() => navigate('/claim')} style={{
                                                    color: '#fff',
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                }}
                                                    className='d-flex flex-row justify-content-between align-items-center cta-button'
                                                >
                                                    <span
                                                        className='m-0'
                                                        style={{
                                                            fontWeight: '500',
                                                        }}
                                                    >Claim Your Hotel</span>
                                                    <ArrowRightOutlined />
                                                </Button>
                                            </div>
                                        </div>

                                        :
                                        null
                        }
                    </nav>
                </div>

                <div
                    className={'w-100 position-absolute' + (breakpoints.lg ? ' d-none' : mobileNavVisible ? '' : ' d-none')}
                    style={{ top: 0, left: 0, height: '100vh', zIndex: 500 }}
                    onClick={() => setMobileNavVisible(!mobileNavVisible)}
                >
                    <div className={'mobile-nav-popup' + (breakpoints.lg ? ' d-none' : mobileNavVisible ? ' d-flex flex-column' : ' d-none')}>
                        <div className='d-flex w-100 justify-content-between align-item-center bg-white p-2'>
                            <img src='/logo2.png' className='logo' alt="Proper Training" />
                            <div
                                onClick={() => {
                                    setMobileNavVisible(!mobileNavVisible);
                                }}
                            >
                                <CloseCircleOutlined style={{ fontSize: 25 }} />
                            </div>
                        </div>
                        <div className='menu'>
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                to='/dashboard'
                            >
                                Dashboard
                            </NavLink>

                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                to='/claim'
                            >
                                Claim Hotel
                            </NavLink>
                            {
                                role === 'ADMIN' &&
                                <React.Fragment>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to='/claim'
                                    >
                                        Cities
                                    </NavLink>

                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to='/claim'
                                    >
                                        Countries
                                    </NavLink>

                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to='/claim_requests'
                                    >
                                        Claim Requests
                                    </NavLink>
                                </React.Fragment>
                            }
                            <span className='nav-link pointer' onClick={_logout}>
                                Logout
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}