import { Col, Row, Tooltip } from 'antd';
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { logout } from '../../store/action/user';
import './styles.less';

export const Footer = () => {

	let location = useLocation();
	let breakpoints = useBreakpoint();
	const invisible_routes = [
		'/hotel/edit'
	];

	const { _id } = useSelector(st => st.user);

	let dispatch = useDispatch();
	let navigate = useNavigate();
	let [, , removeCookie] = useCookies(['jwt']);

	async function _logout() {
		try {
			removeCookie('jwt')
			dispatch(logout())
			navigate('/');
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<footer className={`page-footer font-small footer py-4 foot ${invisible_routes.includes(location.pathname) ? 'd-none' : ''}`}>
			<div className="container">
				<Row className="" gutter={[16, 8]} align='middle'>
					<Col
						xs={{
							span: 24,
							order: 2
						}}
						md={{
							span: 12,
							order: 1
						}}
						className={`d-flex flex-row align-items-center ${breakpoints.md ? 'justify-content-start' : 'justify-content-center'}`}
					>
						<p
							className='mb-0 ms-1 fs-6 text-center text-md-left'
							style={{
								color: '#b5b5b5'
							}}
						>
							© 2024 Copyright: HOFFERO TravTech All Rights Reserved
						</p>

					</Col>
					<Col
						xs={{
							span: 24,
							order: 1
						}}
						md={{
							span: 12,
							order: 2
						}}
						className='d-flex flex-row align-items-center justify-content-xs-center justify-content-end footer-nav-container'
					>
						{
							_id ?
								<div className='text-center mx-2'>
									<a href='#' onClick={_logout}>Logout</a>
								</div>
								:
								<div className='text-center mx-2'>
									<Link to='/login'>Login</Link>
								</div>
						}
						<div className='text-center mx-2'>
							<Link to='/tnc'>{breakpoints.md ? 'Terms And Conditions' : 'TnC'}</Link>
						</div>
						<div className='text-center my-auto mx-2'>
							<Link to='/privacy'>Privacy Policy</Link>
						</div>
					</Col>
				</Row>
			</div>
		</footer>
	);
}
