const initState = {
    hotel: {}
};

export const hotelReducer = (state = initState, action) => {
    switch(action.type) {
        case 'SET_HOTEL': {
            return ({
                ...state,
                hotel: action.payload
            });
        }
        default: 
            return state;
    }
}