import {
    useRef,
    useState
} from 'react';
import { BsRobot } from "react-icons/bs";
import { FaCaretRight } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { Link } from 'react-router-dom';
import './styles.scss';


export default function HoffAI(){
    const [messages, setMessages] = useState([
        {
            type: 'html',
            content: '<p>Hi, I’m <strong>Hoffy</strong> (Travel Bot) 👋</p><p style="margin:0">I can help you with destination information, recommend hotels and more…</p>',
            role: 'bot'
        }
    ]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const messageContainerRef = useRef(null);

    function scrollChatToBottom(){
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }

    async function handlePromptSubmit(){
        try {
            if (!prompt?.trim()?.length || loading) {
                return;
            }
            setLoading(true);
            setMessages((prev) => {
                scrollChatToBottom();
                return [
                    ...prev,
                    {
                        type: 'text',
                        content: prompt,
                        role: 'user'
                    }
                ];
            });
            setPrompt('');
            const localThreadId = localStorage.getItem('hoff-ai-thread-id');
            let body = {
                prompt
            }
            if (localThreadId){
                body.thread_id = localThreadId;
            }
            let res = await fetch(`${process.env.REACT_APP_CHAT_URL}/chatbot/chat`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            res = await res.json();
            if (res.success){
                let newMessages = []
                if (res.response?.html){
                    newMessages.push({
                        type: 'html',
                        content: res.response.html,
                        role: 'bot'
                    });
                } else {
                    newMessages.push({
                        type: 'text',
                        content: res.response.text,
                        role: 'bot'
                    });
                }
                if (res.response?.thread_id){
                    if (localThreadId !== res.response.thread_id){
                        localStorage.setItem('hoff-ai-thread-id', res.response.thread_id);
                    }
                }
                if (res.response?.isHotelPresent){
                    if (res.response?._id){
                        const url = `/hotels/${res.response?.country?.name}/${res.response?.city?.name}/${res.response?.title?.split(' ')?.join('-') + '-' + res.response?._id}`;
                        newMessages.push({
                            type: 'book-now-btn',
                            content: url,
                            role: 'bot'
                        })
                    }
                    if (res.response?.phones?.length > 0){
                        newMessages.push({
                            type: 'phones',
                            content: res.response?.phones,
                            role: 'bot'
                        });
                    }
                    if (res.response?.urls?.length > 0){
                        newMessages.push({
                            type: 'urls',
                            content: res.response.urls,
                            role: 'bot'
                        });
                    }
                    if (res.response?.images?.length > 0){
                        newMessages.push({
                            type: 'images',
                            content: res.response.images,
                            role: 'bot'
                        });
                    }
                } else {
                    if (res.response?.suggested_hotels?.length > 0){
                        newMessages.push({
                            type: 'text',
                            content: 'Here are some suggested hotels',
                            role: 'bot'
                        });
                        newMessages.push({
                            type: 'suggestions',
                            content: res.response.suggested_hotels,
                            role: 'bot'
                        });
                    }
                }
                setMessages((prev) => [
                    ...prev,
                    ...newMessages
                ]);
            } else {
                setMessages((prev) => [
                    ...prev,
                    {
                        type: 'text',
                        content: res.message,
                        role: 'bot'
                    }
                ]);
            }
            
        } catch (err) {
            console.error(err);
            setMessages((prev) => [
                ...prev,
                {
                    type: 'text',
                    content: 'Sorry, I could not understand that. Please try again',
                    role: 'bot'
                }
            ]);
        } finally {
            scrollChatToBottom();
            setLoading(false);
        }
    }

    return (
        <div
            id='hoff-ai'
            className='position-fixed'
            style={{
                zIndex: 1000,
                bottom: 30,
                right: 10,
            }}
        >
            {
                isExpanded && (
                    <div
                        className={`chat-container ${loading ? 'multi-color-border' : ''}`}
                    >
                        <div
                            className='chat-box-container'
                            style={{
                                margin: loading ? 4 : 0
                            }}
                        >
                            <div
                                className='chat-header d-flex align-items-center p-2 justify-content-between'
                            >
                                <div
                                    className='d-flex align-items-center'
                                    style={{
                                        columnGap: 10
                                    }}
                                >
                                    <BsRobot
                                        size={30}
                                        color='white'
                                    />
                                    <span
                                        style={{
                                            color: 'white',
                                            fontSize: 20,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Hoffy
                                    </span>
                                </div>
                                <div
                                    className='blink-animation'
                                    style={{
                                        backgroundColor: 'lightgreen',
                                        borderRadius: '50%',
                                        width: 10,
                                        height: 10
                                    }}
                                />
                            </div>
                            <div
                                className='chat-box p-1'
                                ref={messageContainerRef}
                            >
                                {
                                    messages.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`message-container ${message.role}`}
                                        >
                                            {
                                                message.type === 'text' && (
                                                    <div
                                                        className='message'
                                                    >
                                                        {message.content}
                                                    </div>
                                                )
                                            }
                                            {
                                                message.type === 'html' && (
                                                    <div
                                                        className='message'
                                                        dangerouslySetInnerHTML={{__html: message.content}}
                                                    />
                                                )
                                            }
                                            {
                                                message.type === 'book-now-btn' && (
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                            marginBottom: 8
                                                        }}
                                                    >
                                                        <Link
                                                            to={message.content}
                                                            className='book-now-btn'
                                                        >
                                                            Book Now
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                            {
                                                message.type === 'phones' && message.content?.length > 0 && (
                                                    <div
                                                        className='w-100 message'
                                                        style={{
                                                            gap: 8
                                                        }}
                                                    >
                                                        {
                                                            message.content.map((phone, index) => (
                                                                <div
                                                                    key={index}
                                                                    className='phone-container'
                                                                >
                                                                    <a
                                                                        href={`tel:${phone.phone}`}
                                                                        className='d-block'
                                                                    >
                                                                        - {phone.tech}: {phone.phone}
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                message.type === 'urls' && message.content?.length > 0 && (
                                                    <div
                                                        className='w-100 message'
                                                        style={{
                                                            gap: 8
                                                        }}
                                                    >
                                                        {
                                                            message.content.map((url, index) => (
                                                                <div
                                                                    key={index}
                                                                    className='url-container'
                                                                >
                                                                    <a
                                                                        href={url.url}
                                                                        target='_blank'
                                                                        className='d-block'
                                                                        rel='noreferrer'
                                                                    >
                                                                        - {url.url}
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                (message.type === 'images' && message.content?.length > 0) && (
                                                    <div
                                                        className='d-flex flex-wrap w-100 message'
                                                        style={{
                                                            gap: 8
                                                        }}
                                                    >
                                                        {
                                                            message.content.map((image, index) => (
                                                                <div
                                                                    key={index}
                                                                    className='image-container'
                                                                >
                                                                    <img
                                                                        src={image.url}
                                                                        alt={image['image-name']}
                                                                        style={{
                                                                            width: 100,
                                                                            height: 100,
                                                                            objectFit: 'cover',
                                                                            borderRadius: 10,
                                                                            userSelect: 'none'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                message.type === 'suggestions' && message.content?.length > 0 && (
                                                    <div
                                                        className='w-100 message d-flex flex-row suggestions-container'
                                                        style={{
                                                            gap: 8
                                                        }}
                                                    >
                                                        {
                                                            message.content.map((hotel, index) => (
                                                                <Link
                                                                    key={hotel._id}
                                                                    to={`/hotels/${hotel.country?.name}/${hotel.city?.name}/${hotel.names?.split(' ')?.join('-') + '-' + hotel._id}`}
                                                                >
                                                                    <div
                                                                        key={index}
                                                                        className='suggestion-wrapper d-flex flex-column'
                                                                        style={{
                                                                            backgroundImage: `url(${hotel?.images?.[0]?.url || 'https://via.placeholder.com/150'})`
                                                                        }}
                                                                    >
                                                                        
                                                                        <h5
                                                                            style={{
                                                                                color: 'white',
                                                                                fontSize: 16,
                                                                                fontWeight: 'bold',
                                                                                margin: 10,
                                                                                zIndex: 1
                                                                            }}
                                                                        >
                                                                            {hotel.names}
                                                                        </h5>
                                                                        <div
                                                                            className='position-absolute w-100 h-100'
                                                                            style={{
                                                                                zIndex: 0,
                                                                                background: 'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 50%)'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Link>
                                                                
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div
                                className='input-container'
                            >
                                <input
                                    className='prompt-input'
                                    placeholder='Type your message here...'
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handlePromptSubmit();
                                        }
                                    }}
                                />
                                <div
                                    className='prompt-send-btn-wrapper'
                                    onClick={handlePromptSubmit}
                                >
                                    <FaCaretRight
                                        size={20}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div
                className={`d-flex justify-content-end ${isExpanded ? 'mt-2' : ''}`}
            >
                <div
                    className='p-2 position-relative toggle-btn-container'
                    style={{
                        cursor: 'pointer',
                        backgroundColor: '#0080FF',
                        borderRadius: 999,
                        width: 'fit-content'
                    }}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                        scrollChatToBottom();
                    }}
                >
                    {
                        isExpanded ? (
                            <IoCloseCircle
                                size={30}
                                color='white'
                            />
                        ) : (
                            <BsRobot
                                size={30}
                                color='white'
                            />
                        )
                    }
                    {
                        !isExpanded && (
                            <>
                                <div
                                    className='position-absolute blink-animation'
                                    style={{
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'lightgreen',
                                        borderRadius: '50%',
                                        width: 10,
                                        height: 10
                                    }}
                                />
                                <span className='online-text'>We are Online</span>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}