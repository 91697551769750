import { combineReducers } from 'redux';
import { constReducer } from './const';
import { formReducer } from './form';
import { userReducer } from './user';
import { hotelReducer } from './hotel';

export default combineReducers({
    user: userReducer,
    consts: constReducer,
    form: formReducer,
    hotel: hotelReducer
});