export const setCity = (payload) => ({
    type: 'SET_CITY',
    payload
});

export const setCountry = (payload) => ({
    type: 'SET_COUNTRY',
    payload
});

export const setSearch = (payload) => ({
    type: 'SET_SEARCH',
    payload
});