import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import * as Sentry from "@sentry/react";

import 'react-quill/dist/quill.snow.css';

import "./styles/App.css";
import "./styles/ant.less";
import "./styles/bootstrap.scss";

import { setCity, setCountry } from "./store/action/const";
import { login, logout } from "./store/action/user";

import { getCities, getCountries } from './common/get_consts';

import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Loader } from "./components/loader";
import HoffAI from "./components/HoffAI";

const CookiePopUp = lazy(() => import("./components/Cookie"));
const HotelHome = lazy(() => import("./visitor/Hotel"));
const Home = lazy(() => import("./visitor/Home"));
const HotelSearch = lazy(() => import("./visitor/Search"));
const ContactUs = lazy(() => import("./components/contactus"));
const Login = lazy(() => import("./components/login"));
const ForgotPassword = lazy(() => import("./components/forgotPassword"));
const ResetPassword = lazy(() => import("./components/recoverAccount"));
const Register = lazy(() => import("./components/register"));
const ClaimHotel = lazy(() => import("./visitor/ClaimHotel"));
const Privacy = lazy(() => import("./components/privacy"));
const Destinations = lazy(() => import("./admin/Destinations"));
const AdminDashboard = lazy(() => import("./admin/Dashboard"));
const HotelDataManager = lazy(() => import("./admin/UpdateHotel"));
const AddHotel = lazy(() => import("./admin/AddHotel"));
const ClaimRequests = lazy(() => import("./admin/ClaimRequests"));
const AddHotelRequests = lazy(() => import("./admin/AddRequests"));
const TnC = lazy(() => import("./components/tnc"));

export function WithAuth({ Component, role = [] }) {
	const { _id, role: _role } = useSelector(st => st.user ?? {});
	if (_id && role.includes(_role)) {
		return <Component />
	}
	return <Navigate to='/' />
}

function App() {

	let dispatch = useDispatch();
	let location = useLocation();
	const mainContent = React.useRef(null);
	const [cookie] = useCookies(['userInfo']);
	const [loading, setLoading] = useState(false);

	const { _id } = useSelector(st => st.user ?? {});

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if (mainContent?.current)
			mainContent.current.scrollTop = 0;
	}, [location]);

	const getUserInfo = async () => {
		try {
			setLoading(true);
			let res = await fetch(`${process.env.REACT_APP_API_URL}/user`, { credentials: 'include'});
			let status = res.status;
			res = await res.json();
			if (status === 200) {
				dispatch(login({ ...res.response }));
			} else {
				dispatch(logout());
			}
		} catch (err) {
			console.log(err);
			dispatch(logout());
		} finally {
			setLoading(false);
		}
	};

	async function get_cities () {
		try {
			let cities = await getCities();
			dispatch(setCity(cities));
		} catch (err) {
		}
	}

	async function get_countries () {
		try {
			let country = await getCountries();
			dispatch(setCountry(country));
		} catch (err) {
		}
	}

	async function init() {
		await get_cities();
		await get_countries();
	}

	useEffect(() => {
		if (cookie.userInfo) {
			dispatch(login({ ...cookie.userInfo }));
			getUserInfo();
		}
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <Loader />
	}

	return (
		<React.Fragment>
			<div ref={mainContent} className="d-flex flex-column min-vh-100">
				<Header />
				<main style={{ zIndex: 5 }} className="flex-fill">
					<Routes>
						<Route path="/" element={_id ? <AdminDashboard /> : <Home />} />
						<Route path="hotels" element={<HotelSearch />} />
						<Route path="hotels/:country" element={<HotelSearch />} />
						<Route path="hotels/:country/:city" element={<HotelSearch />} />
						<Route path="hotels/:country/:city/:hotel" element={<HotelHome />} key='hotel_page' />
						<Route path="hotel" element={<HotelHome />} />
						<Route path="login" element={<Login />} />
						<Route path="claim" element={<ClaimHotel />} />
						<Route path="claim_requests" element={<ClaimRequests />} />
						<Route path="destinations" element={<Destinations />} />
						<Route path="add_requests" element={<AddHotelRequests />} />
						<Route path="forgot_password" element={<ForgotPassword />} />
						<Route path="reset_pass" element={<ResetPassword />} />
						<Route path="register" element={<Register />} />
						<Route path="tnc" element={<TnC />} />
						<Route path="privacy" element={<Privacy />} />
						<Route path="contactus" element={<ContactUs />} />
						<Route path="dashboard" element={<WithAuth Component={AdminDashboard} role={['ADMIN', 'USER', 'CREATIVE']} />} />
						<Route path="hotel/add" element={<WithAuth Component={AddHotel} role={['ADMIN']} />} />
						<Route path="hotel/edit/:hotel_id" element={<WithAuth Component={HotelDataManager} role={['ADMIN']} />} />
					</Routes>
				</main>
				<Footer />
				<CookiePopUp />
				<HoffAI />
			</div>
		</React.Fragment>
	);
}

class AppWrapper extends React.Component {
	
	componentDidCatch(err) {
		console.log(err);
		Sentry.captureException(err);
	}

	render() {
		return (<App />)
	}

}

export default AppWrapper;
