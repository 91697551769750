export async function getCities() {
    try {
        let res = await fetch(process.env.REACT_APP_API_URL + '/city', {
            credentials: 'include'
        });
        res = await res.json();
        if (res.success) {
            return res.response;
        }
        throw Error(res.response)
    } catch (e) {
        console.log(e);
    }
}

export async function getCountries() {
    try {
        let res = await fetch(process.env.REACT_APP_API_URL + '/country', {
            credentials: 'include'
        });
        res = await res.json();
        if (res.success) {
            return res.response;
        }
        throw Error(res.response)
    } catch (e) {
        console.log(e);
    }
}