const initState = {
    jwtToken: '',
    fullName: '',
    email: '',
    phone: ''
}

export const userReducer = (state = initState, action) => {
    switch(action.type) {
        case 'LOGIN': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'LOGOUT':
            return ({});
        default: 
            return state;
    }
}