const initState = {
    amenities: {}
};

export const formReducer = (state = initState, action) => {
    switch(action.type) {
        case 'SET_AMENITIES': {
            return ({
                ...state,
                amenities: action.payload
            });
        }
        default: 
            return state;
    }
}