import React from 'react';
import { useMatch } from 'react-router-dom';
import './styles.less';
import { MainHeader } from './header1';

export function Header() {
    let match = useMatch('hotels/:country/:city/:hotel');
    let match2 = useMatch('hotel');

    if(match || match2) return null;
    return <MainHeader />;
}